body {
  text-align: center;
}

nav {
  & :link {
    padding: 0 1rem;
  }
}

main {
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem;
}
